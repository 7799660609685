import { forwardRef, useContext, useState } from "react";
import BaseDialog from "./BaseDialog";
import { Elev } from "@/model/elev";
import { UserContext } from "@/contexts/userContext";
import { giveEleverAccess } from "@/services/elevListeService";
import { createToastEleverVoksenverificeret, createToastEleverIkkeVoksenverificeret } from "@/util/toast";
import useToastStore from "@/store/toastStore";
import useInstitutionId from "@/hooks/institution-id";

type GiveEleverAccessDialogType = {
  elever: Elev[];
  onGiveAccess: () => void;
  close: Function;
};

const GiveEleverAccessDialog = forwardRef<HTMLDialogElement, GiveEleverAccessDialogType>(
  ({ elever, onGiveAccess, close }, ref) => {
    const { csrfToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToastStore();
    const institutionId = useInstitutionId();
    const activateElever = async () => {
      setLoading(true);
      const eleverUuids = elever.map((elev) => elev.getUuid());
      const result = await giveEleverAccess({aktoerIds: eleverUuids, institutionId}, csrfToken);
      setLoading(false);
      if (result.statusCode.code === 200) {
        addToast(createToastEleverVoksenverificeret(elever));
      } else {
        addToast(createToastEleverIkkeVoksenverificeret(elever, result.statusCode));
      }
      onGiveAccess();
    };
    return (
      <BaseDialog
        ref={ref}
        header="Giv ekstra sikkerhed"
        primaryButtonText="Giv adgang"
        secondaryButtonText="Annuller"
        submitCallback={activateElever}
        loading={loading}
        close={() => close()}
      >
        {elever.length === 1 ? (
          <p>
            Vil du give{" "}
            <strong>
              <u>{elever[0].getFullName()}</u>
            </strong>{" "}
            ({elever[0].getKeycloakUsername()}) ekstra sikkerhed i 120 minutter?
          </p>
        ) : (
          <p>
            Vil du give{" "}
            <strong>
              <u>{elever.length}</u>
            </strong>{" "}
            elever ekstra sikkerhed i 120 minutter?
          </p>
        )}
        <ul>
          <li>Giv eleverne ekstra sikkerhed på knappen herunder</li>
          <li>Eleverne kan nu logge ind på sider der kræver ekstra sikkerhed</li>
          <li>Om 120 minutter, slutter ekstra sikkerhed for eleverne</li>
        </ul>
      </BaseDialog>
    );
  },
);

export default GiveEleverAccessDialog;
